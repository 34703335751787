import { Stack, Typography } from "@mui/material";
import { Company, Initiative } from "../../../Store/CompanySlice";
import { useMemo } from "react";
import { GetMaxCycleTime } from "../../../Services/CycleTimeService";
import { metricsFontLarge, metricsFontSmall } from "../../../Styles";
import { plural } from "pluralize";
import { GetCompletedItems, GetItemsBySelectedCategoriesAndCompany } from "../../../Services/CompanyService";
import { InsufficientThroughputSection } from "./InsufficientThroughputSection";

interface CycleTimeMetricsProps {
  company: Company
  initiative: Initiative
}

export function CycleTimeMetrics(props: CycleTimeMetricsProps) {
  const itemsBySelectedCategories = GetItemsBySelectedCategoriesAndCompany(props.company);
  const completedItems = GetCompletedItems(itemsBySelectedCategories, props.company.workflow, [
    { startDate: props.company.metricStartDate, endDate: props.company.metricEndDate }
  ])
    .filter(item => (item.initiativeId === props.initiative.id));

  const cycleDays = useMemo(() => {
    return GetMaxCycleTime(completedItems, 85, props.company.workflow);
  }, [props.company, completedItems]);

  const bigTextSX = metricsFontLarge;
  const smallTextSX = metricsFontSmall;

  return (
    <>
      <Stack sx={{ paddingX: 2 }} gap={1} alignItems="center">
        <InsufficientThroughputSection company={props.company} completedItemCount={completedItems.length}>
          <>
            <Typography sx={bigTextSX}>{cycleDays} days or less</Typography>
            <Typography sx={smallTextSX}>were required to complete</Typography>
            <Typography sx={bigTextSX}>85%</Typography>
            <Typography sx={smallTextSX}>of {plural(props.company.terms.throughputTerm.toLowerCase())}</Typography>
          </>
        </InsufficientThroughputSection>
      </Stack>
    </>
  )
}